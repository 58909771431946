import { inject, Injectable } from '@angular/core';
import {
  ProductListRequestBodyDto,
  ProductListResponseDto,
  ProductsApiService,
  SimpleProductsResponseDto,
} from '@ev-portals/cp/frontend/shared/api-client';
import { FeedbackMessageService } from '@ev-portals/cp/frontend/shared/util';
import { catchError, map, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedProductService {
  #productsApiService = inject(ProductsApiService);
  #feedbackMessageService = inject(FeedbackMessageService);

  getProducts(remoteFilters: ProductListRequestBodyDto): Observable<ProductListResponseDto> {
    return this.#productsApiService
      .getProductList({
        body: remoteFilters,
      })
      .pipe(
        catchError(() => {
          this.#feedbackMessageService.addErrorNotification();

          // Return an empty list if there's an error
          return of({
            filters: [],
            products: [],
            pagination: { total: 0, skip: 0, limit: 0 },
          });
        }),
      );
  }

  getProductsByIds(ids: string[]): Observable<SimpleProductsResponseDto> {
    return this.#productsApiService.getSimpleProductsByIds({
      productIds: ids.join(','),
    });
  }

  getProductOptions(pageIndex: number, searchTerm?: string): Observable<ProductOption[]> {
    const skip = pageIndex * 25;
    return this.#productsApiService.getSimpleProductsList({ skip, limit: 25, searchTerm }).pipe(
      map(productList => {
        return productList.products;
      }),
    );
  }
}

export interface ProductOption {
  id: string;
  name: string;
  sbu: string | null;
}
